
<div class="row remove">
    <mat-icon class="material-icons-outlined" (click)="remove()">
      highlight_off
    </mat-icon >
</div>
  
<agm-map   [latitude]="latitude" [longitude]="longitude"  [zoom]="18">
      <agm-marker  *ngFor="let current of currentLocationList; let i = index"  [latitude]="current.latitude" [longitude]="current.longitude" 
      (mouseOver)="onMouseOver(infoWindow, $event)" 
      (mouseOut)="onMouseOut(infoWindow, $event)"
      [markerDraggable]="true">
          <agm-info-window [disableAutoPan]="false" #infoWindow>
              <div>
                   <b style="color:red">{{current.user.name}}</b><br>
                   {{current.address}}
              </div>
         </agm-info-window>
      </agm-marker>
      <!-- <agm-polyline [strokeColor]="'#800020'" [strokeWeight]=2>
        <agm-polyline-point *ngFor="let line of currentLocationList; let i = index" [latitude]="line.latitude" [longitude]="line.longitude">
        </agm-polyline-point>
      </agm-polyline> -->
  </agm-map>
  