<ng-container *ngIf="isLoading; else elseTemplate">
    <div class="loading">
      <app-loading></app-loading>
      <br>
      <h1>Please Wait Loading...</h1>
    </div>
  </ng-container>
  
  <ng-template #elseTemplate>
      <div class="container">
    
        <div class="mat-elevation-z8">
      
          <div class="header_action">
            <!-- <button mat-flat-button>
              <span class="material-icons">
                location_on
              </span>
              <span>
                Assign Location
              </span>
            </button> -->
            <button mat-flat-button   [routerLink]="['/client-loc-access']" >
              <span class="material-icons">
                add
              </span>
              <span>Create Client</span>
            </button>
    
           
            <mat-form-field appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Search</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="search latitude/ longitude/ address" #input>
            </mat-form-field>
          </div>
          <!-- <div class="filter">
      
          </div> -->
          <table mat-table [dataSource]="dataSource">
      
            <!-- Position Column -->
            <ng-container matColumnDef="sino">
              <th mat-header-cell *matHeaderCellDef class="text-center"> <b>Sl.No</b> </th>
              <td mat-cell *matCellDef="let element; let i =index" class="text-center"> {{ currentPageOffset + i + 1 }}</td>
            </ng-container>
      
            <!-- Name Column -->
            <ng-container matColumnDef="clientid">
              <th mat-header-cell *matHeaderCellDef> <b>Client Code</b> </th>
              <td mat-cell *matCellDef="let element"> {{element.clientCode}} </td>
            </ng-container>
      
      
      
            <!-- Weight Column -->
            <ng-container matColumnDef="clientname">
              <th mat-header-cell *matHeaderCellDef> <b>Client Name</b> </th>
              <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
            </ng-container>
      
            <!-- Symbol Column -->
            <ng-container matColumnDef="viewLocation">
              <th mat-header-cell *matHeaderCellDef> <b>ADDRESS</b> </th>
              <td mat-cell *matCellDef="let element"    (click)="viewAliasNameAddress(element)"> <button class="btn b1" >View Location</button> </td>
            </ng-container>
            <!-- <button *ngIf="element?.client?.locationDetails.length !=0; else nodata" type="button" class="btn b1" style="font-size: 12px;"
            (click)="viewclientaddress(element.client)">
              View Location
            </button> -->
      
            <!-- Symbol Column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> <b>ACTION</b> </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="Example icon-button with a menu"   [matMenuTriggerFor]="beforeMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <div class="side_setting">
  
                  <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <a mat-menu-item (click)="editLocation(element)" style="text-decoration: none;">
                      <span class="material-icons" style="vertical-align: middle; font-size: 22px; margin-right: 3px;">edit</span>
                      <span style="vertical-align: middle;">Edit Client</span>
                    </a>
                    <!-- <a  mat-menu-item  (click)="viewaddress(element)" *ngIf ="element?.locationDetails.length !=0">
                      <span class="material-icons v" style="    transform: translateY(7px);font-size: 22px;;margin-right:3px" >visibility</span>View Address</a>                -->
                  </mat-menu>
                </div>
              </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4" align="center">
                <div class="alert alert-secondary" style="width: 128%;">
                  <h3>No Location List Found {{input.value}}</h3>
                </div>
              <td>
            </tr>
          </table>
      
      
          <mat-paginator (page)="onPageChange($event)" [pageSizeOptions]="[5, 10, 20,30,50]" showFirstLastButtons></mat-paginator>
      
        </div>
      </div>
      </ng-template>
    
    