import { Component, OnInit,ViewChild} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { MatDialog , MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ViewTrackUserListComponent } from '../view-track-user-list/view-track-user-list.component';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { UserLocationComponent  } from '../user-location/user-location.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-create-geofence',
  templateUrl: './create-geofence.component.html',
  styleUrls: ['./create-geofence.component.scss']
})
export class CreateGeofenceComponent implements OnInit {
  assignForm:FormGroup;
  userlist: any=[];
  geoList: any=[];
  submitted: boolean = false;
  isDisabled = true;
  allSelected=false;
  totalData:any=[];
  selectedUsersControl = new FormControl();
  @ViewChild('select') select: MatSelect;
  type: any;
  user: any;
  geofence: any;
  userId: any;
  constructor(private gs: GlobalserviceService, 
    private fb:FormBuilder, 
    private auth: AuthService,
     private router: Router, 
     private datePipe: DatePipe,
     public dialog: MatDialog,
     private datepipe:DatePipe) { }

  ngOnInit(): void {
    let  userdata = this.auth.getLocalStorageData();
      console.log(userdata);
      this.userId=userdata.userId;
      console.log(this.userId);
      let id = this.auth.getLocal_Org().organizationId
      console.log(userdata .userId)
    this.assignForm = this.fb.group({
      user:['',[Validators.required]],
      geofence:['',[Validators.required]]
    })
    // this.gs.get_User_list().subscribe((data: any) => {
    //   console.log(data);
    //   this.userList=data;
    //   console.log(this.userList);
    // })
    if (this.auth.getLocal_Org()?.organizationId) {
      let id = this.auth.getLocal_Org().organizationId
      console.log(userdata .userId)
      this.gs.get_user_org(id).subscribe((a: any) => {
          //  console.log(a);
          //  this.userNameList=a;
          //  console.log(this.userNameList);
           a.map(
             (e: any)=>{
                 if(userdata.userType[0].userTypeName == "BUDDY USER")
                 {
                  if(e.userId !=  userdata.userId && (e.userType[0].userTypeName != "PRIMARY USER" && userdata.userType[0].userTypeName == "BUDDY USER") ){
                    console.log(e);
                    this.userlist.push(e);
                  }
                 }
                 else {
                  if(e.userId !=  userdata.userId){
                    console.log(e)
                     this.userlist.push(e);
                  }
                 }
            }
          )
        // this.dataSource = new MatTableDataSource(this.userlist);
        // this.dataSource.paginator = this.paginator;
        // console.log(a)
      })
    } else {
        
      let id = this.auth.getLocalStorageData()?.userId;
      this.gs.get_primary_users_user(id).subscribe((a: any) => {
        a.map(
          (e: any)=>{
           if(e.userId != id){
           //  console.log(e)
              this.userlist.push(e);
           }
         }
       )
        // this.dataSource = new MatTableDataSource(this.userlist);
        // this.dataSource.paginator = this.paginator;
        // console.log(a)
      })
    }

    this.gs.get_Geofence_list(this.userId).subscribe((d: any) => {
      console.log(d);
      this.geoList=d;
      console.log(this.geoList);
    })
  }

  toggleAllSelection() {
     if (this.allSelected) {
       console.log("select");
       this.select.options.forEach((item: MatOption) => item.select());
      //  this.add();
     } 
     else {
       console.log("unselect");
       this.isDisabled=true;
        } 
  }
  // add(){
  //   console.log(this.employeeForm.value.employee);
  //   console.log(this.employeeForm.value.employeeName);
  //   let x = this.employeeForm.value.employee; 
  //   console.log(x);
  //   if(x.employee == ''){
  //     alert("Please select the options")
  //   }
  //   else{
  //     this.isRowData=x;
  //     console.log(this.id);
  //     console.log(this.isRowData);
  //     if(this.id)
  //     {
  //       console.log("Edit option");
  //       console.log(this.isRowData);
  //       console.log("row Data Length:"+this.isRowData.length);
  //       this.isRowData.forEach((element, index) => {
  //         console.log("element" + element);         
  //          const control = this.employeeForm.get('tableRows') as FormArray;
  //          console.log(control);
  //          console.log(
  //            "Control"+control.length);
  //           this.empLength=control.length;
  //           console.log("employee Length"+this.empLength);
  //          if(!control.value.some(k => k.employeeId  == element))
  //          {
  //           console.log("Control Length"+control.length);
  //           let  lastindex  = control.length -1;
  //            console.log("Index:"+lastindex); 
  //            this.isSubmitted=false;  
  //            this.addRow();
  //            this.empName=element.employeeName;          
  //            console.log(this.empName);

  //           let empobj =   this.employeeList.find(e => e.employeeId  == element)
  //           console.log(empobj);
  //           console.log(this.employeeList);
  //           if(empobj)
  //           {
  //             setTimeout(() => {
  //             control.at(lastindex++).patchValue({
  //               employeeName : empobj.employeeName,
  //               employeeId :empobj.employeeId
  //               });
  //             },2);
  //             console.log(lastindex++);
  //               setTimeout(() => {
  //               document.getElementById("timeerror"+index).style.display="none";
  //               },2);
  //           }
  //           if(this.empLength>=0)
  //             {
  //               this.isDisabled=false;
  //             }
  //             else{
  //                 this.isDisabled=true;
  //             }
  //          }
  //       });
  //     }
  //     else
  //     {
  //       console.log(this.isRowData);
  //       console.log("row Data Length:"+this.isRowData.length);
  //       this.isRowData.forEach((element,index) => {
  //         const control = this.employeeForm.get('tableRows') as FormArray;
  //         console.log(control);
  //         console.log("control Length:"+control.length);
  //         this.empLength=control.length;
  //          if(!control.value.some(k => k.employeeId  == element))
  //          {
  //           let  lastindex  = control.length -1 ;
  //            console.log("Index:"+lastindex);
  //            this.isSubmitted=false;
  //            this.addRow();   
  //            this.empName=element.employeeName;  
  //            console.log(this.empName);        
  //            let empobj =   this.employeeList.find(e => e.employeeId  == element)
  //            console.log(empobj);
  //            console.log(this.employeeList);
  //            if(empobj)
  //            {
  //             setTimeout(() => {
  //               control.at(lastindex++).patchValue({
  //                 employeeName : empobj.employeeName,
  //                 employeeId :empobj.employeeId
  //                 });
  //             }, 2);
  //             console.log(lastindex++);
  //                setTimeout(() => {
  //                 document.getElementById("timeerror"+index).style.display="none";
  //                }, 2);
  //            }
  //             if(this.empLength>=0)
  //             {
  //               this.isDisabled=false;
  //             }
  //             else{
  //                 this.isDisabled=true;
  //             }
  //          }
  //       });
  //     }
       
  //   }
  // }
  assigntype(event:any)
  {
    console.log(event);
    this.type=event.value;
    console.log(this.type);
  }
  get validate() { return this.assignForm.controls; }
  selectuser(event:any)
  {
  console.log("user",event.value);
  this.user=event.value;
  console.log(this.user);
  }
  geofenceselect(event:any)
  {
    console.log("geofence",event.value);
    this.geofence=event.value;
    console.log(this.geofence);
  } 
  userselect(event:any)
  {
    console.log("user",event.value);
    this.user=event.value;
    console.log(this.user);
  }
  selectgeofence(event:any)
  {
    console.log("geofence",event.value);
    this.geofence=event.value;
    console.log(this.geofence);
  }
  onSubmit()
  {
    console.log("Submit");
    this.submitted=true;
    if (this.assignForm.invalid) 
    {
      console.log("invalid")
      return;
    }
    
    if(this.type==2)
    {
      this.totalData = [];
      console.log("Empty Arra",this.totalData);
      console.log(this.geofence);
      console.log(this.user);
      console.log("multiple geofence assign");
      this.geofence.forEach((element) => {
        console.log(element)
        var geofence={
          geofence:{
              geofenaceId: element
          },
          user:{
              userId:this.user
          },
          status:{
            statusId:1
        }
        }
        this.totalData.push(geofence);
        console.log(this.totalData);
      });
    }
    else if(this.type==1)
    {
      this.totalData = [];
      console.log("Empty Arra",this.totalData);
      console.log(this.user);
      console.log(this.geofence);
      console.log("multiple User assign");
      this.user.forEach(element1 => {
        console.log(element1);
        var user={
          geofence:{
              geofenaceId: this.geofence
          },
          user:{
              userId:element1
          },
          status:{
            statusId:1
        }
      }
          this.totalData.push(user);
          console.log(this.totalData);
      });
    }

    this.gs.createassignGeofence(this.totalData).subscribe((d:any)=>{
      console.log(d);
      if(d.statusCode == 200){
        this.submitted=false;
        Swal.fire({
          text:d.description,
          icon:'success',
          timer: 2000,
        })
        setTimeout(() => {
           this.router.navigateByUrl('/assign-geofence');
        }, 2000)
      }
      if(d.statusCode == 409){
        Swal.fire({
          text:d.description,
          icon:'error',
          timer: 2000
        })
        setTimeout(() => {
        }, 2000)
      }
    },
    err=>{
      console.log("Error");
      Swal.fire({
        text:err.error.description,
        icon:'error',
        timer: 2000
      })
      setTimeout(() => {
      }, 2000)
    }
     );
    
    // else
    // {
    //   console.log(this.assignForm.value);
    //   var data={
    //             geofence:{
    //                 // geofenaceId:this.assignForm.value.user
    //                 geofenaceId: this.geofence
    //             },
    //             user:{
    //                 // userId:this.assignForm.value.geofence
    //                 userId:this.user
                    
    //             },
    //             status:{
    //               statusId:1
    //           }
    //   }
    //   console.log("data",data);
    //   this.gs.createassignGeofence(data).subscribe((d:any)=>{
    //       console.log(d);
    //       if(d.statusCode == 200){
    //         this.submitted=false;
    //         Swal.fire({
    //           text:d.description,
    //           icon:'success',
    //           timer: 2000,
    //         })
    //         setTimeout(() => {
    //            this.router.navigateByUrl('/assign-geofence');
    //         }, 2000)
    //       }
    //       if(d.statusCode == 409){
    //         Swal.fire({
    //           text:d.description,
    //           icon:'error',
    //           timer: 2000
    //         })
    //         setTimeout(() => {
    //         }, 2000)
    //       }
    //     },
    //     err=>{
    //       console.log("Error");
    //       Swal.fire({
    //         text:err.error.description,
    //         icon:'error',
    //         timer: 2000
    //       })
    //       setTimeout(() => {
    //       }, 2000)
    //     }
    //      );
    // }
    
  }
}
