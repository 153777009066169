import { Component, OnInit,Inject} from '@angular/core';
import { MatDialog , MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { AuthService } from 'src/app/Authenticaton/auth.service';
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-family-currentlocation',
  templateUrl: './family-currentlocation.component.html',
  styleUrls: ['./family-currentlocation.component.scss']
})
export class FamilyCurrentlocationComponent implements OnInit {
  currentLocationList: any=[];
  address: string;
  latitude: any;
  longitude: any;
  liveId: number;

  constructor(public dialog: MatDialog,
    private gs: GlobalserviceService,
    private auth: AuthService) { }

  ngOnInit(): void {
    console.log("CurrentLOcation");
   
    let  userdata = this.auth.getLocalStorageData();
    console.log(userdata);
    console.log("User Type",userdata.userType);
    console.log("User Type",userdata.userType[0].userTypeName);
      if(userdata.createdByUser==null) 
      {
         console.log("Primary User",userdata.userType.userTypeName);
         this.liveId=userdata.userId;
         console.log(this.liveId);
      }
      else if(userdata.userType[0].userTypeName=="BUDDY USER")
      {
        console.log("Buddy User",userdata.userType.userTypeName);
        this.liveId=userdata.userId;
        console.log(this.liveId);
      }
      else
      {
        this.liveId=userdata.createdByUser;
        console.log(this.liveId);
      }
      // let id = this.auth.getLocal_Org().organizationId
      // console.log(userdata.createdByUser);
      this.gs.getCurrentLocation(this.liveId)
      .subscribe((data: any) =>{
        console.log(data);
        this.currentLocationList=data;
        console.log(this.currentLocationList);
        this.latitude = data[0].latitude;
        console.log("lati",this.latitude);
        this.longitude = data[0].longitude;
        console.log("Long",this.longitude);
      }
      // (error) =>{
      //   console.log("error");
      //   if(error.error.errorCode==204){
      //     // this.userlist =0;
      //   }
      // }
      )
    }

  remove()
    {
      console.log("remove");
      this.dialog.closeAll();
    }

  onMouseOver(infoWindow:any, $event: MouseEvent) 
   {
        //  this.address=this.data.address;
        console.log("open address",this.address);
        infoWindow.open();
    }

  onMouseOut(infoWindow:any, $event: MouseEvent) 
   {
  console.log("close address",this.address);
        infoWindow.close();
    }

}
